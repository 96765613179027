const formId = '8ef03ad257254a3aa4ce1546b4d56c6d';

const loadJS = (url: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        if (!url) {
            reject(new Error('URL is empty'));
            return;
        }
        const websiteId = /linctex/.test(location.hostname)
            ? '0c29292a25b246b882f64e96af3abed6'
            : '73b7b46965f345c387e9a6ce36543415';
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.charset = 'utf-8';
        script.id = 'fsMarketingWebsiteScript';
        script.innerHTML = `FSscriptArgs.WebsiteEa='lingdisz';FSscriptArgs.WebsiteId='${websiteId}';FSscriptArgs.host='www.fxiaoke.com'`;
        window.FsYxtWebsiteEa = 'lingdisz';
        window.FsYxtWebsiteId = websiteId;
        window.FsYxtHost = 'www.fxiaoke.com';

        script.onload = () => {
            resolve();
        };
        script.onerror = () => {
            reject(new Error(`Failed to load script from URL: ${url}`));
        };
        script.src = url;
        document.body.appendChild(script);
    });
};
export const useFormSDK = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        if (window.FsYxt) return resolve(formId);
        loadJS(`https://www.fxiaoke.com/ec/kemai/release/static/marketing-website-access.js?id=${Math.random()}`)
            .then(() => {
                resolve(formId);
            })
            .catch(() => {
                reject(new Error('Failed to load sdk'));
            });
    });
};
